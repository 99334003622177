import io from 'socket.io-client';
import config from '../config/app';

import {
  LISTEN_NEW_GAME,
  LISTEN_GAME_RESULT,
  LISTEN_MATCH_RESULT,
  LISTEN_JOIN_GAME,
  LISTEN_TOSS_COIN,
} from '../authentication/actions';

const opts = {
  forceNew: true,
  reconnection: true,
  path: '/socket.io'
};

export const socket = io(config.realtimeServerAddress, opts);

const startRealtimeConnection = (store) => {
  socket.on('connect', () => {
    console.log('Connected to realtime server');
  });

  socket.on('new-action', (action) => {
    switch (action.type) {
      case 'new_game':
        store.dispatch({
          type: LISTEN_NEW_GAME,
          payload: action.payload,
        });
        break;

      case 'game_result':
        store.dispatch({
          type: LISTEN_GAME_RESULT,
          payload: action.payload,
        });
        break;

      case 'match_result':
        store.dispatch({
          type: LISTEN_MATCH_RESULT,
          payload: action.payload,
        });
        break;

      case 'join_game':
        store.dispatch({
          type: LISTEN_JOIN_GAME,
          payload: action.payload,
        });
        break;

      case 'toss_coin':
        store.dispatch({
          type: LISTEN_TOSS_COIN,
          payload: action.payload,
        });
        break;
    }
  });

  socket.on('disconnect', () => {
    window.location.reload();
    console.log('Connection was closed');
  });
};

export default startRealtimeConnection;
