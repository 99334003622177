import {
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_USER_FETCHING,

  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FETCHING,

  DO_EXCHANGE_ERROR,
  DO_EXCHANGE_SUCCESS,
  DO_EXCHANGE_LOADING,

  DO_CLAIM_ERROR,
  DO_CLAIM_SUCCESS,
  DO_CLAIM_LOADING,

  DO_JOIN_ERROR,
  DO_JOIN_SUCCESS,
  DO_JOIN_LOADING,

  DO_SELECT_ERROR,
  DO_SELECT_SUCCESS,
  DO_SELECT_LOADING,

  LISTEN_JOIN_GAME,
  LISTEN_NEW_GAME,
  LISTEN_GAME_RESULT,
  LISTEN_TOSS_COIN,
  LISTEN_MATCH_RESULT,
} from './actions';

const initialState = {
  loadingUser: false,
  loadingHistory: false,
  loadingExchange: false,
  loadingClaim: false,
  loadingJoin: false,
  loadingSelect: false,
  login: false,
  settings: {},
  milestones: [],
  shop: [],
  user: {},
  game: null,
  active: false,
  memberCount: 0,
  matchesSchedule: [],
  gameResult: null,
  matchResult: null,
  tossResult: null,
  map: [],
  history: {},
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    // JOIN GAME
    case LISTEN_JOIN_GAME:
      return {
        ...state,
        memberCount: payload.member_count,
        matchesSchedule: payload.matches_schedule,
      };
      break;

    // NEW GAME
    case LISTEN_NEW_GAME: {
      const isCurrent = payload?.match?.includes(state?.user?.hidden_uid);
      const gameData = isCurrent ? payload : state?.game;

      return {
        ...state,
        game: gameData,
        gameResult: isCurrent ? null : state?.gameResult,
        matchResult: isCurrent ? null : state?.matchResult,
        tossResult: isCurrent ? null : state?.tossResult,
        user: {
          ...state.user,
          match_id: gameData?.match_id,
        },
        matchesSchedule: isCurrent ? payload.matches_schedule : state?.matchesSchedule,
      };
      break;
    }

    // GAME RESULT
    case LISTEN_GAME_RESULT: {
      const isCurrent = (state?.game?.match_id === payload.match_id);

      if (!isCurrent) {
        return { ...state };
      }

      const key = `${payload.match_id}-${payload.game_number}`;

      if (state.map.includes(key)) {
        return {
          ...state,
          game: null,
          gameResult: payload,
        };
      }

      return {
        ...state,
        game: null,
        gameResult: payload,
        map: [...state.map, key],
      };
      break;
    }

    // MATCH RESULT
    case LISTEN_MATCH_RESULT: {
      const isCurrent = (state?.user?.match_id === payload.match_id);

      if (!isCurrent) {
        return {
          ...state,
          matchesSchedule: (!state?.tossResult) ? payload?.matches_schedule : state?.matches_schedule,
        };
      }

      const { winner, loser_point_balance, winner_point_balance, round, matches_schedule } = payload;
      const isWinner = (state?.user?.hidden_uid === winner);

      return {
        ...state,
        matchResult: payload,
        game: isCurrent ? null : state?.game,
        gameResult: isCurrent ? null : state?.gameResult,
        tossResult: isCurrent ? null : state?.tossResult,
        matchesSchedule: (!isWinner || (round === 2 && isWinner)) ? [] : matches_schedule,
        user: {
          ...state.user,
          point_balance: isWinner ? winner_point_balance : loser_point_balance,
        }
      };
      break;
    }

    case LISTEN_TOSS_COIN: {
      const isCurrent = (state?.user?.match_id === payload.match_id);

      if (!isCurrent) {
        return { ...state };
      }

      return {
        ...state,
        tossResult: payload,
      }
      break;
    }

    // USER
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        login: true,
        shop: payload.shop,
        milestones: payload.accumulate_rewards,
        settings: payload.settings,
        user: payload.user,
        game: payload.game,
        active: payload.active,
        memberCount: payload.member_count,
        matchesSchedule: payload.matches_schedule,
        matchResult: null,
      };
      break;
    case GET_USER_ERROR:
      return {
        ...state,
        loadingUser: false,
        shop: payload?.shop,
        settings: payload?.settings,
        milestones: payload?.accumulate_rewards,
      };
      break;
    case GET_USER_FETCHING:
      return {
        ...state,
        loadingUser: true,
      };
      break;

    // HISTORY
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loadingHistory: false,
        history: payload,
      };
      break;
    case GET_HISTORY_ERROR:
      return {
        ...state,
        loadingHistory: false,
      };
      break;
    case GET_HISTORY_FETCHING:
      return {
        ...state,
        loadingHistory: true,
      };
      break;

    // EXCHANGE
    case DO_EXCHANGE_SUCCESS:
      return {
        ...state,
        loadingExchange: false,
        user: payload.user,
        shop: payload.shop,
      };
      break;
    case DO_EXCHANGE_ERROR:
      return {
        ...state,
        loadingExchange: false,
      };
      break;
    case DO_EXCHANGE_LOADING:
      return {
        ...state,
        loadingExchange: true,
      };
      break;

    // CLAIM
    case DO_CLAIM_SUCCESS:
      return {
        ...state,
        loadingClaim: false,
        user: payload.user,
        milestones: payload.accumulate_rewards,
      };
      break;
    case DO_CLAIM_ERROR:
      return {
        ...state,
        loadingClaim: false,
      };
      break;
    case DO_CLAIM_LOADING:
      return {
        ...state,
        loadingClaim: true,
      };
      break;

    // JOIN
    case DO_JOIN_SUCCESS:
      return {
        ...state,
        loadingJoin: false,
        user: payload.user,
        memberCount: payload.member_count,
      };
      break;
    case DO_JOIN_ERROR:
      return {
        ...state,
        loadingJoin: false,
      };
      break;
    case DO_JOIN_LOADING:
      return {
        ...state,
        loadingJoin: true,
      };
      break;

    // SELECT
    case DO_SELECT_SUCCESS:
      return {
        ...state,
        loadingSelect: false,
      };
      break;
    case DO_SELECT_ERROR:
      return {
        ...state,
        loadingSelect: false,
      };
      break;
    case DO_SELECT_LOADING:
      return {
        ...state,
        loadingSelect: true,
      };
      break;

    // DEFAULT
    default:
      return state;
  }
}
