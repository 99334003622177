import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';

export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function showError(code, title = 'Thông báo') {
  let theme = 'error';
  let msg = '';

  switch (code) {
    case 'not_logged_in':
      window.location.href = '/user/login';
      return;
      // msg = 'Vui lòng đăng nhập để tham gia sự kiện';
      break;

    case 'invalid_time':
      msg = 'Đã hết thời gian chơi trong ngày';
      break;

    case 'account_not_found':
      msg = 'Bạn chưa có tài khoản FO4';
      break;

    case 'transaction_fail':
      msg = 'Giao dịch không thể thực hiện';
      break;

    case 'not_enough_fc':
      msg = 'Bạn không có đủ FC để chơi';
      break;

    case 'invalid_quantity':
      msg = 'Số vật phẩm bạn đổi không hợp lệ';
      break;

    case 'invalid_item':
      msg = 'Vật phẩm bạn chọn không hợp lệ';
      break;

    case 'item_not_found':
      msg = 'Vật phẩm bạn chọn không tìm thấy';
      break;

    case 'playing_game':
      msg = 'Bạn không thể quy đổi quà trong lúc chơi';
      break;

    case 'not_enough_point':
      msg = 'Bạn không đủ điểm để quy đổi phần quà này';
      break;

    case 'item_limit_exceeded':
      msg = 'Số lượng đổi vượt quá giới hạn của phần quà';
      break;

    case 'not_enough_item_amount':
      msg = 'Bạn không có đủ lượt chọn quà';
      break;

    case 'time_is_up':
      msg = 'Thời gian đã hết';
      break;

    case 'can_not_play_multi_turns':
      msg = 'Không thể chơi nhiều lần trong khoảng thời gian này';
      break;

    default:
      msg = 'Hiện không thể thực hiện, vui lòng thử lại sau';
      break;
  }

  return Swal.fire({
    title: `${title}`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
      </div>
    `,
    confirmButtonText: 'Xác nhận',
    showConfirmButton: true,
    showCancelButton: false,
    showCloseButton: false,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    customClass: {
      popup: `popup-alert popup-error ${theme ? `popup-${theme}` : ''} ${title.length ? '' : 'popup-no-title'}`
    },
  })
}

export function showSuccess(code, data = {}, onClose = () => {}, title = '') {
  let theme = 'reward';
  let msg = '';

  switch (code) {
    case 'receive_prize':
      msg = `Chúc mừng <br/>Bạn nhận được`;
      break;

    case 'champion':
      msg = `Chúc mừng bạn <br/>đã hoàn thành giải đấu`;
      break;

    case 'loser':
      msg = `Opp! Rất tiếc<br/> bạn đã dừng chân ở`;
      break;

    default:
      msg = 'Thực hiện thao tác thành công!';
      break;
  }

  return Swal.fire({
    title: `${title}`,
    html: `
      ${msg ? `<div class="popup-alert__message">${msg}</div>` : ''}
      ${data?.rank ? `<div class="popup-alert__rank">Bạn đã đạt<br/><span>${data?.rank}</span></div>` : ''}
      ${data?.round ? `<div class="popup-alert__round">${data?.round}</div>` : ''}
      ${data?.image ? `<div class="popup-alert__image"><img src="${data?.image}"/></div>` : ''}
      ${data?.name ? `<div class="popup-alert__name">${data?.name}</div>` : ''}
      ${data?.note ? `<div class="popup-alert__note">${data?.note}</div>` : ''}
      ${data?.point ? `<div class="popup-alert__point">Điểm đạt được: ${data?.point}</div>` : ''}
    `,
    confirmButtonText: 'Xác nhận',
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: false,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    customClass: {
      popup: `popup-alert popup-success ${theme ? `popup-${theme}` : ''} ${title.length ? '' : 'popup-no-title'}`
    },
    onClose: () => {
      onClose();
    },
  })
}

export function showConfirm(msg = 'Bạn có chắc chắn không?', callback = () => {}, title = 'Thông báo') {
  return Swal.fire({
    title: `${title}`,
    html: `<div class="popup-alert__message">${msg}</div>`,
    confirmButtonText: 'Có',
    showCancelButton: true,
    cancelButtonText: 'Không',
    showCloseButton: true,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    target: 'body',
    reverseButtons: true,
    customClass: {
      popup: `popup-alert popup-confirm ${title.length ? '' : 'popup-no-title'}`
    },
  }).then(res => {
    if (res.value) {
      callback();
    }
  });
}

export function toObj(array, key) {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function isInGame() {
  const result = new URLSearchParams(location.search).get('ingame');
  return !!result;
};

export function isMobile() {
  if ($(window).width() < 1023) {
    return true;
  }

  return false;
};

export function getDateTime(dateTime, format = 'HH:mm DD/MM/YYYY') {
  return dayjs(dateTime).format(format);
};

export function isCurrentAfter(date) {
  return dayjs().isAfter(dayjs(date));
};

export function isCurrentBefore(date) {
  return dayjs().isBefore(dayjs(date));
};

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function format2digits(number) {
  return number < 10 ? ('0' + number) : number;
};

export function checkEvent(started, loggedIn, needLogin = true) {
  if (started === 0) {
    showError('not_started_yet');
    return 0;
  }

  if (needLogin && !loggedIn) {
    showError('not_logged_in');
    return 1;
  }

  return 2;
}

export function ReplaceNewString(str, key, newStr) {
  if (!str) {
    return;
  }

  return str.split(key)[0] + newStr;
}

export function getArray(start, end) {
  return Array(end - start + 1).fill().map((_, i) => start + i);
}

export function getAroundNumbers(num = 0, range = 3, length = 10) {
  let res = [];

  const all = getArray(0, length - 1);
  const nums = all.filter(n => n >= (num - range) && n <= (num + range));
  const index = nums.indexOf(num);

  if (index === range) {
    const missing = (range - (nums.length - 1 - index));

    if (missing === 0) {
      res = [...nums];
    } else {
      const pieces = all.slice(0, missing);
      res = [...nums, ...pieces];
    }
  } else if (index < range) {
    const missing = (range - index);
    const pieces = all.slice(missing * (-1));
    res = [...pieces, ...nums];
  }

  return res;
};

export function groupBy(arr = [], key = '') {
  if (!key || !arr.length) return null;
  return arr.reduce((r, a) => {
    r[a[`${key}`]] = r[a[`${key}`]] || [];
    r[a[`${key}`]].push(a);
    return r;
  }, Object.create(null));
};

export function getRound(round) {
  let res = '';

  switch (round) {
    case 2:
      res = 'Chung kết';
      break;

    case 4:
      res = 'Bán kết';
      break;

    case 8:
      res = 'Tứ kết';
      break;
  }

  return res;
};

export function getRoundId(id) {
  let res;

  if ([2,3].includes(id)) {
    res = 2
  } else if ([4,5,6,7].includes(id)) {
    res = 4;
  } else {
    res = 8;
  }

  return res;
};

export function calPrice(game_type, number_of_turns, settings) {
  let price;

  if (number_of_turns < 10) {
    price = settings.price[game_type] * number_of_turns;
  } else if (number_of_turns < 50) {
    price = Math.floor(number_of_turns * settings.price[game_type] * 0.95);
  } else {
    price = Math.floor(number_of_turns * settings.price[game_type] * 0.9);
  }

  return price;
};
