import request from 'lib/request';
import { showError } from '../lib/commons';

export const GET_USER_ERROR    = 'GET_USER_ERROR';
export const GET_USER_SUCCESS  = 'GET_USER_SUCCESS';
export const GET_USER_FETCHING = 'GET_USER_FETCHING';

export const GET_HISTORY_ERROR    = 'GET_HISTORY_ERROR';
export const GET_HISTORY_SUCCESS  = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FETCHING = 'GET_HISTORY_FETCHING';

export const DO_EXCHANGE_ERROR    = 'DO_EXCHANGE_ERROR';
export const DO_EXCHANGE_SUCCESS  = 'DO_EXCHANGE_SUCCESS';
export const DO_EXCHANGE_LOADING  = 'DO_EXCHANGE_LOADING';

export const DO_CLAIM_ERROR    = 'DO_CLAIM_ERROR';
export const DO_CLAIM_SUCCESS  = 'DO_CLAIM_SUCCESS';
export const DO_CLAIM_LOADING  = 'DO_CLAIM_LOADING';

export const DO_JOIN_ERROR    = 'DO_JOIN_ERROR';
export const DO_JOIN_SUCCESS  = 'DO_JOIN_SUCCESS';
export const DO_JOIN_LOADING  = 'DO_JOIN_LOADING';

export const DO_SELECT_ERROR    = 'DO_SELECT_ERROR';
export const DO_SELECT_SUCCESS  = 'DO_SELECT_SUCCESS';
export const DO_SELECT_LOADING  = 'DO_SELECT_LOADING';

export const LISTEN_JOIN_GAME    = 'LISTEN_JOIN_GAME';
export const LISTEN_NEW_GAME     = 'LISTEN_NEW_GAME';
export const LISTEN_GAME_RESULT  = 'LISTEN_GAME_RESULT';
export const LISTEN_TOSS_COIN    = 'LISTEN_TOSS_COIN';
export const LISTEN_MATCH_RESULT = 'LISTEN_MATCH_RESULT';

// Get user
export const isGetUserFetching = () => {
  return {
    type: GET_USER_FETCHING
  };
}

export const getUserSuccess = (response) => {
  return {
    type: GET_USER_SUCCESS,
    payload: response.payload
  };
}

export const getUserError = (response) => {
  return {
    type: GET_USER_ERROR,
    payload: response.payload
  };
}

export const getUser = () => {
  return (dispatch, getState) => {
    dispatch(isGetUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getUserSuccess(response));
      } else {
        dispatch(getUserError(response));
      }
    });
  };
}

// Get history
export const isGetHistoryFetching = () => {
  return {
    type: GET_HISTORY_FETCHING
  };
}

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  };
}

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload
  };
}

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGetHistoryFetching());
    request('api/user/get-history').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getHistorySuccess(response));
      } else {
        dispatch(getHistoryError(response));
      }
    });
  };
}

// Do exchange
export const isExchanging = () => {
  return {
    type: DO_EXCHANGE_LOADING
  };
}

export const doExchangeSuccess = (response) => {
  return {
    type: DO_EXCHANGE_SUCCESS,
    payload: response.payload
  };
}

export const doExchangeError = (response) => {
  return {
    type: DO_EXCHANGE_ERROR,
    payload: response.payload
  };
}

export const doExchange = (data = {}, callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isExchanging());
    request('api/user/exchange', 'POST', {
      body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(doExchangeSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doExchangeError(response));
        fallback(response);
      }
    });
  };
}

// Do claim
export const isClaiming = () => {
  return {
    type: DO_CLAIM_LOADING
  };
}

export const doClaimSuccess = (response) => {
  return {
    type: DO_CLAIM_SUCCESS,
    payload: response.payload
  };
}

export const doClaimError = (response) => {
  return {
    type: DO_CLAIM_ERROR,
    payload: response.payload
  };
}

export const doClaim = (data = {}, callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request('api/user/claim', 'POST', {
      body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(doClaimSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doClaimError(response));
        fallback(response);
      }
    });
  };
}

// Do join
export const isJoining = () => {
  return {
    type: DO_JOIN_LOADING
  };
}

export const doJoinSuccess = (response) => {
  return {
    type: DO_JOIN_SUCCESS,
    payload: response.payload
  };
}

export const doJoinError = (response) => {
  return {
    type: DO_JOIN_ERROR,
    payload: response.payload
  };
}

export const doJoin = (data = {}, callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isJoining());
    request('api/user/join', 'POST', {
      body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(doJoinSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doJoinError(response));
        fallback(response);
      }
    });
  };
}

// Do select
export const isSelecting = () => {
  return {
    type: DO_SELECT_LOADING
  };
}

export const doSelectSuccess = (response) => {
  return {
    type: DO_SELECT_SUCCESS,
    payload: response.payload
  };
}

export const doSelectError = (response) => {
  return {
    type: DO_SELECT_ERROR,
    payload: response.payload
  };
}

export const doSelect = (data = {}, callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isSelecting());
    request('api/user/select', 'POST', {
      body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(doSelectSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doSelectError(response));
        fallback(response);
      }
    });
  };
}
