import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUser } from '../../authentication/actions';
import Footer from '../Footer';

const PageLayout = ({currentUser, location, children, getUser}) => {
  return (
    <main id="main">
      {children}
      <Footer/>
    </main>
  );
};

const mapDispatchToProps = {
  getUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
